// eslint-disable-next-line no-unused-vars
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import appState from '../utils/state';
import BaseView from './BaseView';

export default class Localisation extends BaseView {
    init() {
        // Brochure gate disabled
        this.prop = true;
        const popup = document.querySelector('#popup-brochure');
        // eslint-disable-next-line no-unused-vars
        const popupContent = document.querySelector('.popup-content');
        // eslint-disable-next-line no-unused-vars
        const form = document.querySelector('#popup-form--brochure');

        const brochureButton = document.querySelector('.brochure-cta');
        // eslint-disable-next-line no-unused-vars
        const btnDownload = document.querySelector('.btn-download');
        const closeButton = document.querySelector('.close-popup ');

        const mainMenu = document.querySelector('#siteHeader');
        const scrollBar = document.getElementsByClassName('c-scrollbar');

        if (brochureButton) {
            brochureButton.addEventListener('click', () => {
                popup.style.display = 'block';
                scrollBar[0].style.display = 'none';
                mainMenu.classList.add('hide');
            });
        }

        if (closeButton) {
            closeButton.addEventListener('click', () => {
                popup.style.display = 'none';
                scrollBar[0].style.display = 'block';
                mainMenu.classList.remove('hide');
            });
        }

        // if ('gatedForm' in localStorage) {
        //     popupContent.style.display = 'none';
        //     btnDownload.style.display = 'block';
        // }

        // const submitForm = (e, formData) => {
        //     e.preventDefault();

        //     const formID = appState.get('lang') === 'en-US' || appState.get('lang') === 'en_US' ? '3585' : '3780';

        //     return axios
        //         .post(`/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`, formData, { maxRedirects: 0 })
        //         .then((response) => {
        //             popupContent.style.display = 'none';
        //             btnDownload.style.display = 'block';
        //             localStorage.setItem('gatedForm', JSON.stringify('true'));
        //             return response;
        //         })
        //         .catch((error) => error);
        // };

        // form.addEventListener('submit', (e) => {
        //     const formData = new FormData(e.target);
        //     submitForm(e, formData);
        // });
    }
}
