/**
 *
 * Main File
 *
 */
import '@babel/polyfill';

import App from './app';

const init = () => {
    App.init();
};

document.addEventListener('DOMContentLoaded', () => init());
