import BaseView from './BaseView';

export default class Career extends BaseView {
    init() {
        this.prop = true;
        const videoLink = document.querySelector('.popup-video');
        const popupVideo = document.querySelector('#popup');
        const closeButton = document.querySelector('.close-modal');
        const mainMenu = document.querySelector('#siteHeader');
        const scrollBar = document.getElementsByClassName('c-scrollbar');
        const iFrame = document.querySelector('.video-iframe');

        videoLink.addEventListener('click', () => {
            iFrame.src += '?autoplay=1&quality=1080p';
            setTimeout(() => {
                popupVideo.classList.remove('hide');
                scrollBar[0].style.display = 'none';
                mainMenu.classList.add('hide');
            }, 800);
        });

        closeButton.addEventListener('click', () => {
            iFrame.src += '';
            popupVideo.classList.add('hide');
            scrollBar[0].style.display = 'block';
            mainMenu.classList.remove('hide');
        });

        document.addEventListener('keydown', (event) => {
            const { key } = event;
            if (key === 'Escape') {
                popupVideo.classList.add('hide');
                iFrame.src += '';
                scrollBar.style.display = 'block';
                mainMenu.classList.remove('hide');
            }
        });
    }
}
