/* eslint-disable no-trailing-spaces */
import LeeroyScroll from '../libs/locomotive-scroll';

export default class BaseView {
    init() {
        this.context = 'Promark';
    }

    static isIE() {
        const ua = navigator.userAgent;
        const isIe = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident/') > -1;
        return isIe;
    }

    static isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || $(window).width() < 1024);
    }

    // Methods

    /**
     * Setup
     *
     * To be run once. Will affect all pages globally
     *
     * @return void
     */
    setup() {
        /* Enleve la class d'animation du body au load */
        setTimeout(() => document.querySelector('.preloader').classList.remove('is--active'), 1000);
        setTimeout(() => document.body.classList.remove('animate'), 800);

        BaseView.initMainMenu();

        this.initLeeroyScroll();

        // Add loaded to body and delete spinner
        document.body.classList.add('loaded');
    }

    // eslint-disable-next-line class-methods-use-this
    initLeeroyScroll() {
        // const page = document.getElementsByTagName('body')[0].dataset.view;
        const header = document.getElementById('siteHeader');
        const cta = document.querySelector('.top__cta--contact');
        const topCta = document.getElementById('topcta');
        const ctaIcon = document.querySelector('.promark-icon-truck');
        // const ctaToggle = document.querySelector('.toggleCta');
        // const serviceBlock2 = document.querySelector('#services_list_block2');
        const links = document.querySelectorAll('a');
        const anchorLinks = Array.from(links).filter((link) => link.href.includes('#'));

        const scroll = new LeeroyScroll({
            el: document.querySelector('#main__inner'),
            smooth: true,
            getDirection: true,
        });
        window.leeroyScroll = scroll;

        scroll.destroy();

        setTimeout(() => {
            scroll.init();
            scroll.on('scroll', (obj) => {
                // ------ CTA ------
                if (cta && topCta && ctaIcon) {
                    ctaIcon.style.transitionDelay = '0s';
                    topCta.style.transitionDelay = '0s';
                    cta.style.transitionDelay = '0s';

                    if (obj.delta) {
                        // TOP CTA COLLAPSE ON SCROLL DOWN
                        if (obj.delta.y > 50) {
                            cta.classList.add('collapse');
                            topCta.classList.add('fixed--anim');
                        } else if (obj.delta.y === 0) {
                            cta.classList.remove('collapse');
                            topCta.classList.remove('fixed--anim');
                        }
                    }
                }

                // DESKTOP MENU FIXED ON SCROLL UP
                if (obj.direction === 'up' && obj.delta.y >= 300) {
                    header.classList.add('fixed');
                } else if (obj.direction === 'down' && obj.delta.y <= 299) {
                    header.classList.add('scrolled');
                    header.classList.remove('fixed');
                } else if (obj.direction === 'up' && obj.delta.y <= 299) {
                    header.classList.remove('fixed');
                    header.classList.remove('scrolled');
                }

                // DESKTOP MENU HIDE ON SCROLL DOWN
                if (header.classList.contains('fixed') && obj.direction === 'down') {
                    header.classList.remove('fixed');
                }
            });
        }, 800);

        // Url already has an anchor link so scroll to it
        const { hash } = window.location;
        if (hash) {
            setTimeout(() => {
                scroll.scrollTo(`${hash}`);
            }, 800);
        }

        anchorLinks.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                // Anchor & Scroll within the same page
                if (window.location.pathname === link.pathname) {
                    const hashIndex = link.getAttribute('href').indexOf('#');
                    const anchor = hashIndex !== -1 ? link.getAttribute('href').substring(hashIndex) : null;
                    scroll.scrollTo(`${anchor}`);
                    header.classList.add('scrolled');
                } else {
                    // Anchor to another page
                    window.location = link;
                }
            });
        });

        if ($('body').attr('data-view') === 'technology') {
            // SCROLL SUR LES TECHNO LINES
            const lines = $('.line');
            const cursor = $('#custom__cursor');
            const cursorText = $('#custom__cursor .text');
            const cursorShape = $('#custom__cursor .shape');

            lines.mouseout(() => {
                cursor.removeClass('show');
                cursorText.text('');

                setTimeout(() => {
                    cursorText.removeClass('left right');
                }, 200);
            });

            lines.mouseenter((e) => {
                cursor.addClass('show');
                cursorShape.css('background-color', $(e.target).attr('data-color'));
                cursorText.text($(e.target).attr('data-text'));
                cursorText.addClass($(e.target).attr('data-text-direction'));
            });

            lines.mousemove((e) => {
                cursor
                    .css('left', e.clientX - 15)
                    .css('top', e.clientY - 15 + scroll.scroll.instance.scroll.y);
            });
        }
    }

    static initMainMenu() {
        /* Click sur burger */
        $(document).on('click', '.burger', () => {
            $('.burger, header').toggleClass('open');
        });

        const burger = document.querySelector('.burger');
        const servicesMenu = document.querySelector('.nav-main .menu-item-has-children');
        const servicesMenuLi = document.querySelectorAll('.nav-main .service-submenu li');
        servicesMenu.addEventListener('click', (e) => {
            // eslint-disable-next-line no-unused-expressions       
            if (burger.classList.contains('open')) {
                // eslint-disable-next-line no-unused-expressions
                e.preventDefault();         
                servicesMenu.classList.toggle('active');
            }
        });

        servicesMenuLi.forEach((item) => {
            item.addEventListener('click', () => {
                if (burger.classList.contains('open')) {
                    $('.burger, header').removeClass('open');
                }
            });
        });
        
        /* Ferme menu mobile au resize */
        $(window).on('resize', () => {
            if ($('header').hasClass('open')) {
                $('.burger, header').removeClass('open');
            }
        });
    }
}
