import axios from 'axios';
import SlimSelect from 'slim-select';
import md5 from 'md5';

import appState from '../utils/state';
import Vanilladator from '../utils/Vanilladator';
import BaseView from './BaseView';
import mapStyle from '../utils/mapStyle.json';
import GoogleMaps from '../utils/GoogleMaps';

export default class Contact extends BaseView {
    constructor() {
        super();

        this.GoogleMap = null;
        this.markers = [];
    }

    init() {
        const self = this;

        this.slimSelect = new SlimSelect({
            select: '.selectmenu',
        });

        this.slimSelect = new SlimSelect({
            select: '.selectmenu-dropdown',
        });

        if (document.forms.contact) {
            document.forms.contact.addEventListener('submit', (e) => Contact.submitForm(e));
        }

        // ------ Google Map ------

        // Marker icon
        const icon = {
            url: '/static/promark_marker.png',
        };

        // Generate a new map and set it to the instance state
        this.GoogleMap = new GoogleMaps(document.getElementById('map'), {
            center: {
                lat: 55.805919,
                lng: -95.849687,
            },
            zoom: 4,
            styles: mapStyle,
        });

        // ------ Locations in DOM ------

        const locations = document.querySelectorAll('[data-pin-id]');

        const cities = Array.from(locations).map((c) => {
            const city = c;
            const id = city.getAttribute('data-pin-id');

            // Set click events on cities in DOM
            city.onclick = () => {
                window.dispatchEvent(new Event('citiesRemoveActive'));
                city.classList.add('active');

                const [marker] = self.markers.filter((m) => m.id === id);

                this.GoogleMap.map.panTo(marker.position);
                this.GoogleMap.map.setZoom(8);
            };

            // Remove class when triggering removal
            window.addEventListener('citiesRemoveActive', () => {
                city.classList.remove('active');
            });

            // Add city to cities map
            return city;
        });

        // ------ Generate markers ------

        window.addEventListener('gmapsReady', () => {
            // Parse pins from website
            const pins = JSON.parse(document.getElementById('mapPins').innerHTML);

            // Map markers objects
            const markers = pins.map((pin) => ({
                id: md5(pin.city),
                title: pin.city,
                position: {
                    lat: parseFloat(pin.coordinates.lat),
                    lng: parseFloat(pin.coordinates.lng),
                },
            }));

            // Set markers on map
            markers.forEach((m) => {
                const marker = new window.google.maps.Marker({
                    ...m,
                    map: self.GoogleMap.map,
                    icon,
                });

                marker.addListener('click', () => {
                    this.GoogleMap.map.panTo(marker.position);
                    this.GoogleMap.map.setZoom(8);

                    const [city] = cities.filter((c) => c.getAttribute('data-pin-id') === marker.id);

                    window.dispatchEvent(new Event('citiesRemoveActive'));

                    city.classList.add('active');
                });

                self.markers.push(marker);
            });

            // Set map bounds from markers on map
            const bounds = new window.google.maps.LatLngBounds();

            self.markers.forEach((m) => {
                bounds.extend(m.position);
            });

            this.GoogleMap.map.fitBounds(bounds);
        });
    }

    static async submitForm(event) {
        event.preventDefault();

        const formMessages = document.querySelector('.form-messages');
        const success = document.querySelector('.success');
        const failure = document.querySelector('.error');
        const formData = new FormData(event.target);
        const formID = (appState.get('lang') === 'en-US') ? '274' : '275';
        const vdator = new Vanilladator(event.target);
        let response;
        const location = document.querySelector('#location');

        formData.append('_wpcf7_unit_tag', `wpcf7-f${formID}-112233445566`);

        if (vdator.validity() && !vdator.formLock) {
            vdator.lock(true);

            // don't fall in the jar
            if (location.value === '') {
                response = await Contact.postForm(formData, formID);

                vdator.lock(false);

                if (response.data.status === 'mail_sent') {
                    formMessages.classList.add('show');
                    success.classList.add('show');
    
                    event.target.reset();
                } else {
                    formMessages.classList.add('show');
                    failure.classList.add('show');
                }
    
                setTimeout(() => {
                    formMessages.classList.remove('show');
                    success.classList.remove('show');
                    failure.classList.remove('show');
                }, 5000);
            }

  
        } else {
            window.dispatchEvent(new Event('resize'));
        }
    }

    static async postForm(formData, formID) {
        return axios
            .post(`/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`, formData)
            .then((response) => response)
            .catch((error) => error);
    }
}
