// Modules

// State
import appState from './utils/state';

// Utils

// Views
import Home from './views/Home';
import Services from './views/Services';
import Career from './views/Career';
import Technology from './views/Technology';
import Contact from './views/Contact';
import Legal from './views/Legal';
import Localisation from './views/Localisation';
import P404 from './views/P404';

class App {
    constructor() {
        this.appViews = new Map();
        this.appWidgets = new Map();

        // Gather page settings
        this.currentView = document.body.getAttribute('data-view');

        if (!this.currentView) {
            throw new Error('Twig variable currentView probably not set on twig template!');
        }

        appState.set('lang', document.documentElement.lang);
        appState.set('currentView', this.currentView);

        // Views list
        this.appViews.set('home', (el) => new Home(el));
        this.appViews.set('services', (el) => new Services(el));
        this.appViews.set('technology', (el) => new Technology(el));
        this.appViews.set('career', (el) => new Career(el));
        this.appViews.set('contact', (el) => new Contact(el));
        this.appViews.set('legal', (el) => new Legal(el));
        this.appViews.set('localisation', (el) => new Localisation(el));
        this.appViews.set('P404', (el) => new P404(el));
    }

    init() {
        // Load Utils
        App.loadUtils();

        // Load views
        this.loadViews();

        // Load widgets
        this.loadWidgets();
    }

    static loadUtils() {}

    loadViews() {
        const currentViewName = appState.get('currentView');

        if (this.appViews.has(currentViewName)) {
            const CurrentView = this.appViews.get(currentViewName);
            const currentView = new CurrentView(this);

            currentView.setup();
            currentView.init();
        }
    }

    loadWidgets() {
        const widgets = document.querySelectorAll('[data-widget]');

        Array.from(widgets).forEach((element) => {
            const widgetName = element.getAttribute('data-widget');

            if (this.appWidgets.has(widgetName)) {
                const widget = this.appWidgets.get(widgetName)(element);
                widget.init();
            }
        });
    }
}

export default new App();
