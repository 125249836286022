export default class GoogleMaps {
    constructor(element, opts) {
        this.element = element;
        this.opts = opts;
        this.map = null;
        this.markers = [];

        this.mount();

        window.addEventListener('gmapsReady', () => {
            this.init();
        });
    }

    mount() {
        const gmaps = document.getElementById('gmapScript');

        if (!gmaps) {
            window.initmaps = () => {
                window.dispatchEvent(new Event('gmapsReady'));
            };

            const script = document.createElement('script');
            script.id = 'gmapScript';
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCIzmJ-8ymcPPCdofuORKHVWJLlNQUhJaY&callback=window.initmaps';
            document.body.appendChild(script);
        } else if (window.google) {
            this.init();
        }
    }

    init() {
        this.map = new window.google.maps.Map(this.element, this.opts);
    }
}
