export default class Vanilladator {
    constructor(form) {
        this.form = form;
        this.elements = form.querySelectorAll('[data-validate]');
        this.isFormValid = null;
        this.formLock = false;
    }

    validity() {
        this.isFormValid = true;

        Array.from(this.elements).forEach((el) => {
            const isInputValid = Vanilladator.validateElement(el);

            if (!isInputValid) {
                this.isFormValid = false;
            }
        });

        return this.isFormValid;
    }

    lock(key) {
        this.formLock = (key);
        Array.from(this.elements).forEach((element) => {
            const el = element;
            el.disabled = (key);
        });
    }

    static validateElement(el) {
        const { validity } = el;

        if (!validity.valid) {
            el.parentElement.classList.add('error');

            if (validity.valueMissing) {
                el.parentElement.classList.add('error--required');
            } else {
                el.parentElement.classList.remove('error--required');
            }

            if (validity.patternMismatch || validity.typeMismatch) {
                el.parentElement.classList.add('error--invalid');
            } else {
                el.parentElement.classList.remove('error--invalid');
            }

            return false;
        }

        el.parentElement.classList.remove('error');
        el.parentElement.classList.remove('error--required');
        el.parentElement.classList.remove('error--invalid');

        return true;
    }
}
